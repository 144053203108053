export default {
	"搜索引擎": "Search Engine",
	"谷歌 (google)": "Google",
	"必应 (Bing)": "Bing",
	"雅虎 (Yahoo)": "Yahoo",
	"脸书 (Facebook)": "Facebook",
	"电报 (Telegram)": "Telegram",
	"社交媒体": "Social Media",
	"X （推特）": "X (Twitter)",
	"油管 (Youtube)": "Youtube",
	"奈飞 (Netflix)": "Netflix",
	"亚马逊 (Amazon)": "Amazon",
	"电商网站": "E-commerce",
	"AI网站": "AI Website",
	"视频网站": "Video Website",
	"我的反馈": "My Feedback",
	"反馈问题处理时间：10:00~23:00（中国区时间），我们将尽快回复您，请耐心等待，稍安勿躁。": "Feedback processing hours: 10:00-23:00. We will respond to your inquiry as soon as possible. Please be patient and stay calm.",
	"请选择反馈的问题类型": "Select feedback category",
	"准确详细的反馈将优先回复，信息不全/不准确的工单将不予回复。": "Complete and accurate information will be prioritized. Submissions with insufficient or incorrect information will not be addressed.",
	"请在此输入反馈信息": "Enter your feedback",
	"添加图片：": "Add Image: ",
	"点击添加": "Click to Add",
	"正在上传": "Uploading...",
	"上一步": "Previous",
	"确认删除图片？": "Delete this image?",
	"提交": "Submit",
	"参数错误": "Invalid Parameters",
	"支持的文件类型：png, jpg": "Allowed file types: PNG, JPG",
	"文件不大于5M": "Maximum file size: 5MB",
	"上传成功": "Upload Successful",
	"上传失败": "Upload Failed",
	"反馈信息不能为空": "Feedback cannot be empty",
	"提交成功": "Successfully submitted",
	"提交失败": "Failed to submit",
	"删除成功": "Delete successful",
	"删除失败": "Failed to delete",
	"达到工单图片上传上限": "Maximum number of images reached",
	"空": "Empty",
	"状态：": "Status: ",
	"创建时间：": "Creation time: ",
	"更新时间：": "Update time: ",
	"新建问题反馈": "Create new feedback",
	"等待回复": "Awaiting response",
	"已回复": "Responded",
	"问题描述：": "Problem description: ",
	"反馈类型：": "Feedback type: ",
	"工单状态：": "Ticket status: ",
	"工单图片：": "Ticket images: ",
	"发送": "Send",
	"请在此输入信息": "Please enter information here",
	"发送成功": "Successfully sent",
	"发送失败": "Failed to send",
	"复制成功": "Successfully copied",
	"跳转分享记录页面": "Go to sharing history",
	"查看分享记录": "View sharing history",
	"分享码": "Share Code",
	"点此复制分享链接": "Click to copy share link",
	"使用指南": "User Guide",
	"1. 将您的分享码发送给好友，您的好友在注册时输入分享码；": "1. Share your referral code with friends who can enter it during registration;",
	"2. 您好友的试用时间将会翻倍；": "2. Your friend's trial period will be doubled;",
	"3. 每当您的好友购买会员时长，您将获得购买时长的20%作为奖励，或以USDT方式提现至您的数字钱包。": "3. When your friend purchases membership time, you'll receive 20% of their purchased duration as a reward, or can withdraw it in USDT to your digital wallet.",
	"注册": "Register",
	"分红": "Dividends",
	"注册时间：": "Registration time: ",
	"全部领取": "Claim All",
	"分红时间：": "Dividend Time: ",
	"分红时长：": "Dividend Duration: ",
	"分红USDT：": "Dividend USDT: ",
	"已领取": "Claimed ",
	"没有可提现项": "No items to withdraw",
	"最小提现额度": "Minimum withdrawal amount ",
	"请联系客服进行提现操作": "Please contact customer service for withdrawal",
	"操作成功！": "Success!",
	"天数：": "Days: ",
	"客服": "Support",
	"我": "Me",
	"取消": "Cancel",
	"确认": "Confirm",
}