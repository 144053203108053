import axios from "axios";

let server = null
let isLoading = false
let loadingEle = null
let toastBody = null
const urlList = [
	'https://rev0.aizhiyuan.cc',
	'https://rev1.aizhiyuan.cc',
	'https://rev0.khljbzxpeytzgsqgtfki.cc',
	'https://rev1.khljbzxpeytzgsqgtfki.cc',
	'https://rev0.nebulabinaryproxy.win',
	'https://rev1.nebulabinaryproxy.win',
	'https://rev0.goalphacloud.com',
	'https://rev1.goalphacloud.com',
]

function getDefaultLang () {
	const urlParams = new URLSearchParams(window.location.search)
	const langParam = urlParams.get('lang')
	return langParam === 'en' ? 'en' : 'zh'
}

async function getServerUrl (index = 0) {

	if (server) {
		return
	}
	if (window.Android && typeof window.Android.onEvent === 'function') {
		window.Android.onEvent('showLoading', JSON.stringify({tag: 'getServerUrl'}))
	} else {
		showLoading()
	}
	try {
		const url = urlList[index]
		const result = await getRequestAsync('web/test', url)
		if (result?.ok === true) {
			const text = await result.text()

			server = url

		} else if (index < urlList.length) {
			const newIndex = index + 1
			await getServerUrl(newIndex)
		}
	} finally {
		if (window.Android && typeof window.Android.onEvent === 'function') {
			window.Android.onEvent('hideLoading', JSON.stringify({tag: 'getServerUrl'}))
		}
		else {
			hideLoading()
		}
	}

}

async function getRequestAsync(url, baseUrl = null) {
	const myHeaders = new Headers();
	// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	// myHeaders.append("Host", "124.220.215.2");
	myHeaders.append("Connection", "keep-alive");
	// const sessionId = localStorage.getItem('sessionId')
	// if (sessionId) {
	// 	myHeaders.append("sessionId", sessionId);
	// }

	// Add cache-control headers to prevent caching
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	};

	// const self = this
	let response
	if (baseUrl) {
		response = await fetch(`${baseUrl}/${url}`, requestOptions)
		return response
	} else {
		response = await fetch(`${server}/${url}`, requestOptions)
		return response.json()
	}
}

async function postRequestAsync(url, data, retry = 0) {
	const myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Accept", "*/*");
	myHeaders.append("Connection", "keep-alive");
	myHeaders.append("Cache-Control", "no-cache");
	myHeaders.append("Pragma", "no-cache");
	myHeaders.append("Expires", "0");

	const raw = JSON.stringify(data);
	const controller = new AbortController();
	const signal = controller.signal;

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
	};

	// 设置15秒超时
	const timeout = setTimeout(() => {
		controller.abort();
	}, 3000);

	// const self = this

	try {
		const response = await fetch(`${server}/${url}`, requestOptions)
		clearTimeout(timeout); // 清除超时计时器
		return response.json()
	} catch (e) {
		if (retry < 3) {
			const newRetry = retry + 1
			return await postRequestAsync(url, data, newRetry)
		} else {
			return null
		}

	} finally {
		clearTimeout(timeout); // 清除超时计时器
	}

}

function showToast(text) {
	if (toastBody) {
		document.body.removeChild(toastBody)
		toastBody = null
	}
	toastBody = document.createElement("div");
	toastBody.classList.add('sl-toast');
	toastBody.innerText = text
	document.body.append(toastBody)
	const timeoutID = window.setTimeout(() => {
		if (toastBody) {
			document.body.removeChild(toastBody)
			toastBody = null
		}
		window.clearTimeout(timeoutID)
	}, 3000);
}

async function uploadFile(url, formData, uploadProgress, uploadSuccess, uploadFail) {
	if (window.Android && typeof window.Android.onEvent === 'function') {
		window.Android.onEvent('showLoading', JSON.stringify({tag: 'uploadFile'}))
	} else {
		showLoading()
	}

	axios.post(`${server}/${url}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		onUploadProgress: function (progressEvent) {
			// 计算上传进度的百分比
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			uploadProgress(percentCompleted)
			// console.log(`Upload progress: ${percentCompleted}%`);
			// 你可以在这里根据百分比来更新UI，例如进度条或者状态信息
		}
	}).then(response => {
		uploadSuccess(response.data)
	})
		.catch(error => {
			uploadFail()
		}).finally(() => {
		if (window.Android && typeof window.Android.onEvent === 'function') {
			window.Android.onEvent('hideLoading', JSON.stringify({tag: 'uploadFile'}))
		} else {
			hideLoading()
		}
	});
}

function showLoading() {
	if (isLoading === false) {
		const loadingContainer = document.createElement("div")
		loadingContainer.classList.add('loader-container')
		let loadingBody = document.createElement("div")
		loadingBody.classList.add('loader');
		loadingContainer.append(loadingBody)
		// toastBody.innerText = text
		document.body.append(loadingContainer)
		isLoading = true
		loadingEle = loadingContainer
	}

}

function hideLoading() {
	if (loadingEle) {
		document.body.removeChild(loadingEle)
		isLoading = false
		loadingEle = null
	}
}

async function verifyTicketSessionId(sessionId) {
	const response = await postRequestAsync('customerServicePanel/verifyTicketSessionId', {ticketSessionId: sessionId})
	if (response && response?.code === 200) {
		return true
	}
	return false
}

function checkFile(file) {
	// 获取文件名
	const fileName = file.name;

	// 获取文件大小（以字节为单位）
	const fileSize = file.size;

	// 获取文件后缀
	const fileExtension = fileName.split('.').pop().toLowerCase();

	// 设置允许的文件类型和最大文件大小（例如：5MB）
	const allowedExtensions = ['jpg', 'jpeg', 'png'];
	const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

	// 检查文件类型
	if (!allowedExtensions.includes(fileExtension)) {
		console.log('不支持的文件类型');
		return 'FILE_NOT_SUPPORT';
	}

	// 检查文件大小
	if (fileSize > maxFileSize) {
		console.log('文件大小超过限制');
		return 'FILE_TOO_LARGE';
	}

	console.log('文件验证通过');
	return 'PASS';
}

export {
	getRequestAsync,
	postRequestAsync,
	showToast,
	uploadFile,
	verifyTicketSessionId,
	checkFile,
	showLoading,
	hideLoading,
	getServerUrl,
	getDefaultLang
}