<template>
	<div class="chat-msg-container-cs" v-if="this.chatItem?.isClientChat !== true">
		<div class="chat-msg-icon-container">
			<img src="../assets/cs.svg" alt="customer service" class="chat-msg-icon">
			<div class="chat-msg-icon-text">{{$t('客服')}}</div>
		</div>
		<div class="chat-msg-content-container-cs">
			<div class="chat-msg-content">
				{{ this.chatItem?.chatContent }}
			</div>
			<div class="chat-msg-time">{{ this.chatItem?.createdTimeString }}</div>
		</div>

	</div>
	<div class="chat-msg-container-user" v-else>

		<div class="chat-msg-content-container-user">
			<div class="chat-msg-content">
				{{ this.chatItem?.chatContent }}
			</div>
			<div class="chat-msg-time-user">{{ this.chatItem?.createdTimeString }}</div>
		</div>

		<div class="chat-msg-icon-container">
			<img src="../assets/user.svg" alt="user" class="chat-msg-icon">
			<div class="chat-msg-icon-text">{{$t('我')}}</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ChatMsgContainer",
	components: {},
	props: ['chatItem'],
	data() {
	}
}
</script>
<style scoped>
.chat-msg-container-cs {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: start;
	margin-bottom: 1.4em;
}

.chat-msg-container-user {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: start;
	margin-bottom: 1.4em;
	justify-content: flex-end;
}

.chat-msg-icon-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.chat-msg-icon {
	width: 3em;
}

.chat-msg-icon-text {
	font-size: 1em;
	line-height: 1;
	margin-top: 0.2em;
}

.chat-msg-content {
	font-size: 1.4em;
	background-color: rgba(230, 213, 184, 0.4);
	text-align: start;
	padding: 0.3em;
	border-radius: 0.1em;
}

.chat-msg-content-container-cs {
	display: flex;
	flex-direction: column;
	margin-left: 0.4em;
}



.chat-msg-content-container-user {
	display: flex;
	flex-direction: column;
	margin-right: 0.4em;
}

.chat-msg-time {
	font-size: 1em;
	margin-top: 0.2em;
	color: rgba(230, 213, 184, 0.4);
}

.chat-msg-time-user{
	font-size: 1em;
	margin-top: 0.2em;
	width: 100%;
	text-align: right;
	color: rgba(230, 213, 184, 0.4);
}
</style>