<template>
	<div class="page-container" v-if="this.canShowContent === true">
		<div class="tab-container">
			<div class="tab-text" :class="{'active': this.currentSelectedTab === 'register'}"
			     @click="this.onTabClicked('register')">{{$t('注册')}}
			</div>
			<div class="tab-text" :class="{'active': this.currentSelectedTab === 'dividend'}"
			     @click="this.onTabClicked('dividend')">{{$t('分红')}}
			</div>
		</div>
		<div v-if="this.currentSelectedTab === 'register'" class="dividend-list-container">
			<a-pagination v-model:current="this.referencePage" v-model:pageSize="this.referencePageSize"
			              :total="this.referenceCount" @change="onReferencePageChanged" style="padding: 0.8em 0;"
			              :showSizeChanger="false" show-less-items/>
			<div v-for="item in this.referenceList" :key="item.item" class="dividend-container">
				<div class="dividend-email">{{ item.referToEmail }}</div>
				<div class="dividend-buy-time">{{$t('注册时间：')}}{{ this.formatTimeString(item.createTimestamp * 1000) }}</div>
			</div>
		</div>
		<div v-else-if="this.currentSelectedTab === 'dividend'" class="dividend-list-container">
			<div class="dividend-list-toolbar">
				<div class="dividend-list-btn" @click="getWithdrawableDividend">{{$t('全部领取')}}</div>
			</div>
			<a-pagination v-model:current="this.dividendPage" v-model:pageSize="this.dividendPageSize"
			              :total="this.dividendCount" @change="onDividendPageChanged" style="padding: 0.8em 0;"
			              :showSizeChanger="false" show-less-items/>
			<div v-for="item in this.dividendList" :key="item.item" class="dividend-container">
				<div class="dividend-email">{{ item.buyerUserEmail }}</div>
				<div class="dividend-buy-time">{{$t('分红时间：')}}{{ this.formatTimeString(item.timestamp * 1000) }}</div>
				<div class="dividend-time-amount">{{$t('分红时长：')}}{{ item.receiveDuration / (24 * 3600) }}天</div>
				<div class="dividend-usdt-amount">{{$t('分红USDT：')}}{{ (item.receiveUSDT / 100).toFixed(2) }}</div>
				<div class="dividend-received" v-if="item.isReceived === true">{{$t('已领取')}}</div>
			</div>
		</div>
		<ConfirmModal v-if="this.showConfirmDividendModal === true" @confirm="onConfirmDividend()"
		              @cancel="onCancelDividend()">
			<div class="confirm-dividend-container">
				<div class="confirm-dividend-container-row" @click="onConfirmDividendRadioClicked('DURATION')">
					<img class="confirm-dividend-select-icon" src="../assets/radio_unselected.svg"
					     alt="radio unselected" v-if="this.selectedDividendType !== 'DURATION'">
					<img class="confirm-dividend-select-icon" src="../assets/radio_selected.svg" alt="radio selected"
					     v-if="this.selectedDividendType === 'DURATION'">
					<div>{{$t('天数：')}}{{ this.formatDividendDuration(this.totalDuration) }}</div>
				</div>
				<div class="confirm-dividend-container-row" @click="onConfirmDividendRadioClicked('USDT')">
					<img class="confirm-dividend-select-icon" src="../assets/radio_unselected.svg"
					     alt="radio unselected" v-if="this.selectedDividendType === 'DURATION'">
					<img class="confirm-dividend-select-icon" src="../assets/radio_selected.svg" alt="radio selected"
					     v-if="this.selectedDividendType !== 'DURATION'">
					<div>USDT：{{ this.formatDividendUsdt(this.totalUsdt) }}</div>
				</div>
			</div>
		</ConfirmModal>
	</div>
	<div class="page-container" v-else>
		{{$t('参数错误')}}
	</div>
</template>
<script>
import {hideLoading, postRequestAsync, showLoading, showToast, verifyTicketSessionId} from "@/utils/methods";
import {timezone} from "@/utils/constants";
import ConfirmModal from "@/components/ConfirmModal.vue";

const moment = require('moment-timezone')
export default {
	name: "ReferenceRecordPage",
	components: {ConfirmModal},
	data() {
		return {
			canShowContent: true,
			ticketSessionId: null,
			currentSelectedTab: 'register',
			dividendList: [],
			referenceList: [],
			dividendPage: 1,
			referencePage: 1,
			dividendCount: 0,
			referenceCount: 0,
			referencePageSize: 10,
			dividendPageSize: 10,
			showConfirmDividendModal: false,
			totalUsdt: 0,
			totalDuration: 0,
			selectedDividendType: 'DURATION',
			dividendRecordCount: 0
		}
	},
	async mounted() {
		await this.initPage()
		await this.getDividendRecord()
		await this.getReferenceRecord()
	},
	methods: {
		async initPage() {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				window.Android.onEvent('showLoading', JSON.stringify({tag: 'verifyTicketSessionId'}));
			}
			// else if (window.chrome && window.chrome.webview) {
			// 	window.chrome.webview.postMessage(JSON.stringify({
			// 		type: 'showLoading',
			// 		tag: 'verifyTicketSessionId'
			// 	}));
			// }
			else {
				showLoading()
			}
			try {
				this.ticketSessionId = this.$route.query.id;
				if (!this.ticketSessionId) {
					this.canShowContent = false
				} else {
					const isSessionValid = await verifyTicketSessionId(this.ticketSessionId)
					if (isSessionValid === false) {
						this.canShowContent = false
					}
				}
			} catch (e) {
				console.log(e.message)
			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'verifyTicketSessionId'}));
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'verifyTicketSessionId'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		async getDividendRecord() {
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'getDividendRecord'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showLoading',
				// 		tag: 'getDividendRecord'
				// 	}));
				// }
				else {
					showLoading()
				}
				const response = await postRequestAsync('reference/getReferDividendRecords', {
					ticketSessionId: this.ticketSessionId,
					page: this.dividendPage
				})
				if (response && response?.code === 200) {
					this.dividendList = response.msg.dividendRecords
					this.dividendCount = response.msg.count
				}

			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'getDividendRecord'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'getDividendRecord'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		async getReferenceRecord() {
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'getReferenceRecord'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showLoading',
				// 		tag: 'getReferenceRecord'
				// 	}));
				// }
				else {
					showLoading()
				}
				const response = await postRequestAsync('reference/getReferRegisterRecord', {
					ticketSessionId: this.ticketSessionId,
					page: this.referencePage
				})
				if (response && response?.code === 200) {
					this.referenceList = response.msg.referenceRecords
					this.referenceCount = response.msg.count
				}
			} finally {

				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'getReferenceRecord'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'getReferenceRecord'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		formatTimeString(timestamp) {
			return moment(parseInt(timestamp)).tz(timezone).format('YYYY-MM-DD');
		},
		onTabClicked(selected) {
			this.currentSelectedTab = selected
		},
		async onDividendPageChanged() {
			await this.getDividendRecord()
		},
		async onReferencePageChanged() {
			await this.getReferenceRecord()
		},
		async getWithdrawableDividend() {
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'getWithdrawableDividend'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showLoading',
				// 		tag: 'getWithdrawableDividend'
				// 	}));
				// }
				else {
					showLoading()
				}
				const response = await postRequestAsync('reference/getWithdrawableDividend', {
					ticketSessionId: this.ticketSessionId
				})
				if (response && response?.code === 200) {
					this.totalUsdt = response.msg.totalUsdt
					this.totalDuration = response.msg.totalDuration
					this.dividendRecordCount = response.msg.recordCount
					this.showConfirmDividendModal = true
				}
			} finally {

				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'getWithdrawableDividend'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'getWithdrawableDividend'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		onConfirmDividendRadioClicked(type) {
			this.selectedDividendType = type
		},
		async onConfirmDividend() {
			if (this.dividendRecordCount === 0) {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showToast', JSON.stringify({msg: this.$t(`没有可提现项`)}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showToast',
				// 		msg: `没有可提现项`
				// 	}));
				// }
				else {
					showToast(this.$t(`没有可提现项`))
				}
				return
			}
			if (this.selectedDividendType === 'DURATION') {
				await this.doWithdrawDuration()
				return
			}

			const minimalUsdt = 1000
			if (this.selectedDividendType === 'USDT' && this.totalUsdt < 1000) {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showToast', JSON.stringify({msg: `${this.$t('最小提现额度')}${(minimalUsdt / 100).toFixed(2)}`}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showToast',
				// 		msg: `最小提现额度${(minimalUsdt / 100).toFixed(2)}`
				// 	}));
				// }
				else {
					showToast(`${this.$t('最小提现额度')}${(minimalUsdt / 100).toFixed(2)}`)
				}
				return
			}

			if (window.Android && typeof window.Android.onEvent === 'function') {
				window.Android.onEvent('showToast', JSON.stringify({msg: this.$t(`请联系客服进行提现操作`)}))
			}
			// else if (window.chrome && window.chrome.webview) {
			// 	window.chrome.webview.postMessage(JSON.stringify({
			// 		type: 'showToast',
			// 		msg: `请联系客服进行提现操作`
			// 	}));
			// }
			else {
				showToast(this.$t(`请联系客服进行提现操作`))
			}
		},
		onCancelDividend() {
			this.showConfirmDividendModal = false
		},
		formatDividendDuration(duration) {
			return duration / 3600 / 24
		},
		formatDividendUsdt(amount) {
			return (amount / 100).toFixed(2)
		},
		async doWithdrawDuration() {
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'doWithdrawDuration'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showLoading',
				// 		tag: 'doWithdrawDuration'
				// 	}));
				// }
				else {
					showLoading()
				}
				const response = await postRequestAsync('reference/withdrawDividendDuration', {
					ticketSessionId: this.ticketSessionId
				})
				if (response && response?.code === 200) {
					if (window.Android && typeof window.Android.onEvent === 'function') {
						window.Android.onEvent('showToast', JSON.stringify({msg: this.$t(`操作成功！`)}))
					}
					// else if (window.chrome && window.chrome.webview) {
					// 	window.chrome.webview.postMessage(JSON.stringify({
					// 		type: 'showToast',
					// 		msg: '操作成功！'
					// 	}));
					// }
					else {
						showToast(this.$t(`操作成功！`))
					}
					this.showConfirmDividendModal = false
					await this.getDividendRecord()
				}

			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'doWithdrawDuration'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'doWithdrawDuration'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		}
	}
}
</script>
<style scoped>
.tab-container {
	display: flex;
	flex-direction: row;
}

.tab-text {
	color: rgba(255, 137, 0, 0.4);
	font-size: 1.6em;
	padding: 0 0.2em;
	border-bottom: 1px solid rgba(255, 137, 0, 0.4);
	cursor: pointer;
}

.tab-text.active {
	color: var(--primary-color);
	border-bottom: 1px solid rgba(255, 137, 0, 1) !important;
}

.dividend-container {
	width: 100%;
	padding: 0.4em 0;
	box-sizing: border-box;
	border-bottom: 1px solid var(--primary-color);
	margin-bottom: 0.6em;
}

.dividend-email {
	color: var(--primary-color);
	font-size: 1.6em;
	width: 100%;
	text-align: start;
	line-height: 1;
	margin-bottom: 0.3em;
}

.dividend-list-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.dividend-buy-time {
	color: var(--white-color);
	text-align: start;
	font-size: 1.4em;
	line-height: 1;
	margin-bottom: 0.3em;
}

.dividend-time-amount {
	color: var(--white-color);
	text-align: start;
	font-size: 1.4em;
	line-height: 1;
	margin-bottom: 0.3em;
}

.dividend-usdt-amount {
	color: var(--white-color);
	text-align: start;
	font-size: 1.4em;
	line-height: 1;
	margin-bottom: 0.3em;
}

.dividend-received {
	color: var(--primary-color);
	text-align: end;
	font-size: 1.4em;
	line-height: 1;
	margin-top: 0.3em;
}

.dividend-list-toolbar {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.6em 0;
	width: 100%;
	cursor: pointer;
}

.dividend-list-btn {
	background-color: var(--primary-color);
	color: var(--background-color);
	padding: 0.4em;
	font-size: 1.2em;
	border-radius: 0.2em;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dividend-list-btn:active {
	transform: scale(0.9);
}

.confirm-dividend-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: start;
}

.confirm-dividend-container-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	font-size: 1.6em;
	margin-bottom: 1em;
}

.confirm-dividend-select-icon {
	width: 1em;
	margin-right: 0.2em;
}

</style>