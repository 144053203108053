<template>
	<div class="page-container" v-if="this.canShowContent === true">
		<div class="refer-code-label">{{$t('分享码')}}</div>
		<div class="refer-code-container" @click="this.copyReferCode()">
			<div class="refer-code-wrapper">{{ this.referCode }}</div>
			<img class="refer-code-copy-btn" src="../assets/copy.svg" alt="copy">
		</div>
		<div class="refer-link-container" @click="this.copyReferLink()">
			<div class="refer-link-wrapper">{{$t('点此复制分享链接')}}</div>
			<img class="refer-code-copy-btn" src="../assets/link.svg" alt="link">
		</div>

		<div class="manual-container">
			<div class="manual-title">{{$t('使用指南')}}</div>
			<div class="manual-content">{{$t('1. 将您的分享码发送给好友，您的好友在注册时输入分享码；')}}</div>
			<div class="manual-content">{{$t('2. 您好友的试用时间将会翻倍；')}}</div>
			<div class="manual-content">{{$t('3. 每当您的好友购买会员时长，您将获得购买时长的20%作为奖励，或以USDT方式提现至您的数字钱包。')}}
			</div>
		</div>

		<div class="open-reference-record-page" @click="this.onReferenceRecord()">
			<div class="open-reference-record-btn">{{$t('查看分享记录')}}</div>
			<img src="../assets/right-arrow-primary.svg" alt="right arrow primary"></div>
	</div>
	<div class="page-container" v-else>
		参数错误
	</div>
</template>
<script>
import {hideLoading, postRequestAsync, showLoading, showToast, verifyTicketSessionId} from "@/utils/methods";

export default {
	name: "ReferCodePage",
	components: {},
	data() {
		return {
			canShowContent: true,
			ticketSessionId: null,
			referLink: null,
			referCode: null
		}
	},
	async mounted() {
		await this.initPage()
		await this.getReferInfo()
	},
	methods: {
		async initPage() {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				window.Android.onEvent('showLoading', JSON.stringify({tag: 'verifyTicketSessionId'}))
			}
			// else if (window.chrome && window.chrome.webview) {
			// 	window.chrome.webview.postMessage(JSON.stringify({
			// 		type: 'showLoading',
			// 		tag: 'verifyTicketSessionId'
			// 	}));
			// }
			else {
				showLoading()
			}
			try {
				this.ticketSessionId = this.$route.query.id;
				if (!this.ticketSessionId) {
					this.canShowContent = false
				} else {
					const isSessionValid = await verifyTicketSessionId(this.ticketSessionId)
					if (isSessionValid === false) {
						this.canShowContent = false
					}
				}
			} catch (e) {
				console.log(e.message)
			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'verifyTicketSessionId'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'verifyTicketSessionId'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		async getReferInfo() {
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'getReferInfo'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showLoading',
				// 		tag: 'getReferInfo'
				// 	}));
				// }
				else {
					showLoading()
				}
				const response = await postRequestAsync('customerServicePanel/getReferInfo', {
					ticketSessionId: this.ticketSessionId
				})
				if (response && response?.code === 200) {
					this.referLink = response.msg.referLink
					this.referCode = response.msg.referCode
				}

			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'getReferInfo'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'getReferInfo'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		copyReferCode() {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				// 触发自定义事件
				window.Android.onEvent('copyReferCode', JSON.stringify({referCode: this.referCode}));
			} else if (window.chrome && window.chrome.webview) {
				window.chrome.webview.postMessage(JSON.stringify({
					type: 'copyReferCode',
					referCode: this.referCode
				}));
				showToast(this.$t('复制成功'))
			}
		},
		onReferenceRecord(url) {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				// 触发自定义事件
				window.Android.onEvent('openReferenceRecordPage', '');
			} else if (window.chrome && window.chrome.webview) {
				window.chrome.webview.postMessage(JSON.stringify({
					type: 'openReferenceRecordPage'
				}));
			} else {
				showToast(this.$t('跳转分享记录页面'))
			}
		},
		copyReferLink() {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				// 触发自定义事件
				window.Android.onEvent('copyReferLink', JSON.stringify({referLink: this.referLink}));
			} else if (window.chrome && window.chrome.webview) {
				window.chrome.webview.postMessage(JSON.stringify({
					type: 'copyReferLink',
					referLink: this.referLink
				}));
				showToast(this.$t('复制成功'))
			}

		}
	}
}
</script>

<style scoped>
.refer-code-label {
	color: var(--primary-color);
	font-size: 1.6em;
	width: 100%;
	text-align: start;
	line-height: 1;
}

.refer-code-container {
	display: flex;
	flex-direction: row;
	color: var(--primary-color);
	font-size: 1.6em;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin: 2em 0;
	cursor: pointer;
}

.refer-link-container {
	display: flex;
	flex-direction: row;
	color: var(--primary-color);
	font-size: 1.6em;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-bottom: 2em;
	cursor: pointer;
}

.refer-code-wrapper, .refer-link-wrapper {
	width: fit-content;
	line-height: 1;
	font-size: 1em;
}

.refer-code-copy-btn {
	width: 1em;
}

.manual-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	color: var(--white-color);
	font-size: 1.6em;
	align-items: start;
}

.manual-title {
	width: 100%;
	text-align: center;
	margin-bottom: 0.6em;
}

.manual-content {
	text-align: start;
	margin-bottom: 0.6em;
}

.open-reference-record-page {
	color: var(--primary-color);
	width: 100%;
	text-align: end;
	font-size: 1.6em;
	margin-top: 1em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: end;
	padding-bottom: 2em;
}

.open-reference-record-btn {
	line-height: 1;
	cursor: pointer;
}
</style>