const questionTree = [
	{
		label: '加速器使用问题',
		labelEn: 'VPN Usage Issues',
		key: 'VPN_USAGE_PROBLEM',
		next: [
			{
				label: '安卓（手机端）',
				labelEn: 'Android (Mobile)',
				key: 'ANDROID_DEVICE',
				next: [
					{
						label: '华为鸿蒙看这里',
						labelEn: 'For Huawei HarmonyOS Users',
						key: 'HUAWEI_DEVICE',
						isFinal: true,
						additional: '附加说明，请尽可能详细描述您遇到的问题。',
						additionalEn: 'Additional note: Please describe your issue in as much detail as possible.',
						solution: '部分华为鸿蒙系统用户发现连接加速后无网络，' +
							'是因为升级鸿蒙系统后默认禁止了加速器的后台活动。\n解决方案：\n方法一：重装加速器App，请至官网下载最近版本（官网在主页菜单中）。' +
							'\n方法二：在系统设置中，找到启动管理/应用启动管理，关闭加速器App的自动管理开关，并在弹窗中勾选允许后台活动。'
					},
					{
						label: '网站/App无法访问',
						labelEn: 'The website/app is inaccessible.',
						key: 'Web_APP_UNABLE_TO_ACCESS',
						next: [
							{
								label: '国内和国外网站/App都无法访问',
								labelEn: 'Both domestic and international websites/apps are inaccessible.',
								key: 'WEB_APP_UNABLE_TO_ACCESS_BOTH',
								next: [
									{
										label: '以前正常，现在无法访问',
										labelEn: 'It worked normally before, but now it\'s inaccessible.',
										key: 'USED_TO_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												labelEn: 'Baidu is accessible.',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														labelEn: 'Can open Google normally.',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														labelEn: 'Cannot open Google.',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														additionalEn: 'Please describe the issue you\'re experiencing in as much detail as possible.',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												labelEn: 'Baidu is inaccessible.',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												isFinal: true,
												isRequired: false
											}
										]
									},
									{
										label: '一直都无法访问',
										labelEn: 'Consistently unable to access.',
										key: 'NEVER_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												labelEn: 'Baidu is accessible.',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														labelEn: 'Can open Google normally.',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														additionalEn: 'Please list the websites/apps that are inaccessible.',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														labelEn: 'Cannot open Google.',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												labelEn: 'Baidu is inaccessible.',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												additionalEn: 'Please describe the issue you\'re experiencing in as much detail as possible.',
												isFinal: true,
												isRequired: false
											}
										]
									}
								]
							},
							{
								label: '只是国外网站/App无法访问',
								labelEn: 'Only international websites/apps are inaccessible.',
								key: 'WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
								next: [
									{
										label: '以前正常，现在无法访问',
										labelEn: 'It worked normally before, but now it\'s inaccessible.',
										key: 'USED_TO_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												labelEn: 'Baidu is accessible.',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														labelEn: 'Can open Google normally.',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														additionalEn: 'Please list the websites/apps that are inaccessible.',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														labelEn: 'Cannot open Google.',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														additionalEn: 'Please describe the issue you\'re experiencing in as much detail as possible.',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												labelEn: 'Baidu is inaccessible.',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												additionalEn: 'Please describe the issue you\'re experiencing in as much detail as possible.',
												isFinal: true,
												isRequired: false
											}
										]
									},
									{
										label: '一直都无法访问',
										labelEn: 'Consistently unable to access.',
										key: 'NEVER_BE_ABLE_TO_ACCESS',
										additional: '连接加速器后，浏览器打开谷歌搜索是否可以访问https://google.com',
										additionalEn: 'After connecting to the accelerator, can you access Google search at https://google.com through your browser?',
										next: [
											{
												label: '谷歌可以访问',
												labelEn: 'Google is accessible.',
												key: 'GOOGLE_CAN_ACCESS',
												additional: '请提供无法访问的网站/App。',
												additionalEn: 'Please list the websites/apps that are inaccessible.',
												isFinal: true,
												isRequired: true
											},
											{
												label: '谷歌无法访问',
												labelEn: 'Google is inaccessible.',
												key: 'GOOGLE_CANNOT_ACCESS',
												isFinal: true,
												additional: '请尽可能详细描述您遇到的问题。',
												additionalEn: 'Please describe the issue you\'re experiencing in as much detail as possible.',
												isRequired: false
											}
										]
									}
								]
							},
							{
								label: '某个网站无法访问',
								labelEn: 'A specific website is inaccessible.',
								key: 'WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
								next: [
									{
										label: '谷歌可以访问',
										labelEn: 'Google is accessible.',
										key: 'GOOGLE_CAN_ACCESS',
										additional: '请提供无法访问的网站/App。',
										additionalEn: 'Please list the websites/apps that are inaccessible.',
										isFinal: true,
										isRequired: true
									},
									{
										label: '谷歌无法访问',
										labelEn: 'Google is inaccessible.',
										key: 'GOOGLE_CANNOT_ACCESS',
										isFinal: true,
										additional: '请尽可能详细描述您遇到的问题。',
										additionalEn: 'Please describe the issue you\'re experiencing in as much detail as possible.',
										isRequired: true
									}
								]
							},
						]
					},
					{
						label: '网站/App访问速度慢',
						labelEn: 'Website/app access is slow.',
						key: 'Web_APP_ACCESS_SLOW',
						next: [
							{
								label: '所有网站/App都慢',
								labelEn: 'All websites/apps are slow.',
								key: 'ALL_WEB_APP_ACCESS_SLOW',
								next: [
									{
										label: '以前不慢，只是现在慢',
										labelEn: 'It was fine before, but now it\'s slow.',
										key: 'USED_TO_BE_FAST',
										additional: '附加说明：请描述您当前的访问速度，以及期望的访问速度。',
										additionalEn: 'Additional note: Please describe your current access speed and your expected/desired speed.',
										isFinal: true,
										isRequired: true
									},
									{
										label: '一直都慢',
										labelEn: 'Consistently slow.',
										key: 'NEVER_BE_FAST',
										isFinal: true,
										additional: '附加说明：请描述您当前的访问速度，以及期望的访问速度。',
										additionalEn: 'Additional note: Please describe your current access speed and your expected/desired speed.',
										isRequired: true
									}
								]
							},
							{
								label: '某个网站/App慢',
								labelEn: 'A specific website/app is slow.',
								key: 'SOME_WEB_APP_ACCESS_SLOW',
								next: [
									{
										label: '以前不慢，只是现在慢',
										labelEn: 'It was fine before, but now it\'s slow.',
										key: 'USED_TO_BE_FAST',
										additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
										additionalEn: 'Additional note: Please provide:\n' +
											'1. The slow-performing websites/apps\n' +
											'2. Your current access speed and desired access speed.',
										isFinal: true,
										isRequired: true
									},
									{
										label: '一直都慢',
										labelEn: 'It has always been slow.',
										key: 'NEVER_BE_FAST',
										isFinal: true,
										additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
										additionalEn: 'Additional note: Please provide:\n' +
											'1. The slow-performing websites/apps\n' +
											'2. Your current access speed and desired access speed.',
										isRequired: true
									}
								]
							}
						]
					},
					{
						label: '加速器连接失败',
						labelEn: 'Accelerator Connection Failed.',
						key: 'VPN_CONNECT_FAILED',
						isFinal: true,
						additional: '请提供您看到的失败信息。',
						additionalEn: 'Please provide the error message you see.',
						isRequired: true
					},
					{
						label: '安卓其他版本问题',
						labelEn: 'Issues with Other Android Versions',
						key: 'ANDROID_OTHER_VERSION_PROBLEM',
						isFinal: true,
						additional: '安卓（手机端）请输入您遇到的问题或者意见反馈【如果属于无法访问/速度慢问题，请使用专属类目进行反馈，否则将不予处理】。',
						additionalEn: 'For Android (Mobile), please describe your issue or feedback [For access problems or slow speeds, please use the dedicated category for feedback, otherwise your request will not be processed].',
						isRequired: true
					},
				]
			},
			{
				label: '苹果iOS（手机端）',
				labelEn: 'iOS (Mobile)',
				key: 'IOS_DEVICE',
				isFinal: true,
				additional: '苹果手机正在上架审核中，敬请期待。或者您可以使用三方客户端作为备选方案，请访问仪表板查看使用说明：https://www.alphacloud.cc/。如有其他问题可在下方反馈。',
				isRequired: true
			},
			{
				label: 'Windows（电脑端）',
				labelEn: 'Windows (PC)',
				key: 'WINDOWS_DEVICE',
				next: [
					{
						label: '加速器启动失败',
						labelEn: 'Failed to launch VPN',
						key: 'VPN_START_FAILED',
						isFinal: true,
						additional: '请提供启动失败看到的错误信息。',
						solution: '亲爱的用户，如果您发现pc客户端无法连接，请按照下面的项目排查：\n' +
							'1、关闭所有杀毒软件；\n' +
							'2、如果电脑系统时间不准，一定要校准；\n' +
							'3、卸载\\禁用浏览器代理插件（如果用的谷歌浏览器）；\n' +
							'4、尝试右键以管理员身份运行加速器。',
						isRequired: true
					},
					{
						label: '网页无法访问',
						labelEn: 'Unable to access webpage.',
						key: 'WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
						next: [
							{
								label: '国内和国外网站都无法访问',
								labelEn: 'Both domestic and international websites are inaccessible.',
								key: 'ALL_WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
								next: [
									{
										label: '以前正常，现在无法访问',
										labelEn: 'It worked before but is now inaccessible.',
										key: 'USED_TO_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												labelEn: 'Baidu is accessible.',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														labelEn: 'Google opens normally.',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														additionalEn: 'Please list the websites/apps that you cannot access.',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														labelEn: 'Cannot access Google.',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														additionalEn: 'Please describe the issue you\'re experiencing in as much detail as possible.',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												labelEn: 'Baidu is not accessible.',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												additionalEn: 'Please describe the issue you\'re experiencing in as much detail as possible.',
												isFinal: true,
												isRequired: false
											}
										]
									},
									{
										label: '一直都无法访问',
										labelEn: 'Consistently unable to access.',
										key: 'NEVER_BE_ABLE_TO_ACCESS',
										next: [
											{
												label: '百度可以访问',
												labelEn: 'Baidu is accessible',
												key: 'BAIDU_CAN_ACCESS',
												next: [
													{
														label: '可以正常打开谷歌',
														labelEn: 'Google opens normally.',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '请提供无法访问的网站/App。',
														isFinal: true,
														isRequired: true
													},
													{
														label: '打不开谷歌',
														labelEn: 'Cannot access Google.',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '请尽可能详细描述您遇到的问题。',
														isRequired: false
													}
												]
											},
											{
												label: '百度无法访问',
												labelEn: 'Baidu is not accessible.',
												key: 'BAIDU_CANNOT_ACCESS',
												additional: '请尽可能详细描述您遇到的问题。',
												isFinal: true,
												isRequired: false
											}
										]
									}
								]
							},
							{
								label: '只是国外网站无法访问',
								labelEn: 'Only foreign websites are inaccessible.',
								key: 'ONLY_FOREIGN_WEB_APP_UNABLE_TO_ACCESS',
								next: [
									{
										label: '国外网站以前可以访问，现在不行了',
										labelEn: 'Foreign websites that were previously accessible are now unreachable.',
										key: 'USED_TO_BE_ABLE_TO_ACCESS',
										isFinal: true,
										additional: '如上面的方法仍未解决您的问题，请在下方尽可能详细的描述您遇到的问题。',
										additionalEn: 'f the above solutions haven\'t resolved your issue, please provide a detailed description of the problem you\'re experiencing below.',
										solution: '尝试进行以下处理能否解决问题：\n1、关闭所有杀毒软件；\n2、如果电脑系统时间不准确，一定要校准；\n3、卸载\\禁用浏览器代理插件（如果用的谷歌浏览器）\n' +
											'4、尝试右键以管理员身份运行加速器',
										solutionEn: 'Try the following solutions to resolve the issue:\n1.Turn off all antivirus software\n2.Ensure your system time is accurate and calibrate if necessary\n3.Uninstall/disable browser proxy extensions (if using Chrome)\n4.Try running the VPN as administrator (right-click and select "Run as administrator")',
										isRequired: true
									},
									{
										label: '一直都无法访问',
										labelEn: 'Consistently unable to access',
										key: 'NEVER_BE_ABLE_TO_ACCESS',
										additional: '连接加速器后，浏览器打开百度（https://baidu.com）是否可以访问',
										additionalEn: 'After connecting to the VPN, can you access Baidu (https://baidu.com) in your browser?',
										next: [
											{
												label: '可以正常打开百度',
												labelEn: 'You can access Baidu normally.',
												key: 'BAIDU_CAN_ACCESS',
												additional: '连接加速器后，浏览器打开谷歌搜索是否可以访问https://google.com',
												next: [
													{
														label: '可以正常打开谷歌',
														labelEn: 'You can access Google normally.',
														key: 'GOOGLE_CAN_ACCESS',
														additional: '您说的无法访问是指哪个网站？',
														isFinal: true,
														isRequired: true
													},
													{
														label: '无法打开谷歌',
														labelEn: 'You cannot access Google normally.',
														key: 'GOOGLE_CANNOT_ACCESS',
														isFinal: true,
														additional: '如上面的方法仍未解决您的问题，请在下方尽可能详细的描述您遇到的问题。',
														solution: '尝试进行以下处理能否解决问题：\n1、关闭所有杀毒软件；\n2、如果电脑系统时间不准确，一定要校准；\n3、卸载\\禁用浏览器代理插件（如果用的谷歌浏览器）\n' +
															'4、尝试右键以管理员身份运行加速器',
														isRequired: true
													}
												]
											},
											{
												label: '无法打开百度',
												labelEn: 'You cannot access Baidu normally.',
												key: 'BAIDU_CANNOT_ACCESS',
												isFinal: true,
												additional: '请尽可能详细描述您遇到的问题。',
												isRequired: false
											}
										]
									}
								]
							},
							{
								label: '某个网站无法访问',
								labelEn: 'A website cannot be accessed.',
								key: 'WEB_APP_UNABLE_TO_ACCESS_FOREIGN',
								next: [
									{
										label: '谷歌可以访问',
										labelEn: 'Google is accessible.',
										key: 'GOOGLE_CAN_ACCESS',
										additional: '请提供无法访问的网站名称或网址。',
										additionalEn: 'Please provide the name or URL of the inaccessible website.',
										isFinal: true,
										isRequired: true
									},
									{
										label: '谷歌无法访问',
										labelEn: 'Google is inaccessible.',
										key: 'GOOGLE_CANNOT_ACCESS',
										additional: '请提供无法访问的网站名称或网址。',
										additionalEn: 'Please provide the name or URL of the inaccessible website.',
										isFinal: true,
										isRequired: true
									}
								]
							},
						]
					},
					{
						label: '网站访问速度慢',
						labelEn: 'The website is loading slowly.',
						key: 'WEB_APP_ACCESS_SLOW',
						next: [
							{
								label: '所有网站都慢',
								labelEn: 'All websites are slow.',
								key: 'ALL_WEB_APP_ACCESS_SLOW',
								additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
								isFinal: true,
								isRequired: true
							},
							{
								label: '某个网站慢',
								labelEn: 'A website is slow.',
								key: 'SOME_WEB_APP_ACCESS_SLOW',
								next: [
									{
										label: '之前不慢，只是现在慢',
										labelEn: 'It was fine before, but now it\'s slow.',
										key: 'USED_TO_BE_SLOW',
										additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
										additionalEn: 'Additional notes: Please provide:\n1. The slow website/app\n2. Describe your current access speed and expected access speed.',
										isFinal: true,
										isRequired: true
									},
									{
										label: '一直都慢',
										labelEn: 'It has always been slow.',
										key: 'ALWAYS_SLOW',
										isFinal: true,
										additional: '附加说明：请提供 1、访问慢的网站/App；2、请描述您当前的访问速度，以及期望的访问速度。',
										additionalEn: 'Additional notes: Please provide:\n1. The slow website/app\n2. Describe your current access speed and expected access speed.',
										isRequired: true
									}
								]
							}
						]
					},
					{
						label: 'Window其他版本问题',
						labelEn: 'Issues with other Windows versions.',
						key: 'WINDOW_OTHER_VERSION_PROBLEM',
						solution: '亲爱的用户，如果您发现pc客户端无法连接，请按照下面的项目排查：\n' +
							'1、关闭所有杀毒软件；\n' +
							'2、如果电脑系统时间不准，一定要校准；\n' +
							'3、卸载\\禁用浏览器代理插件（如果用的谷歌浏览器）；\n' +
							'4、尝试右键以管理员身份运行加速器。',
						solutionEn: 'Dear user, if you find that the PC client cannot connect, please check the following items:\n1. Close all antivirus software\n2. If your computer\'s system time is incorrect, make sure to adjust it\n3. Uninstall/disable browser proxy plugins (if using Google Chrome)\n4. Try right-clicking and running the accelerator as administrator',
						additional: 'Windows（电脑端）请输入您遇到的问题或者意见反馈【如果属于无法访问/速度慢问题，请使用专属类目进行反馈，否则将不予处理】。',
						additionalEn: 'For Windows (PC), please describe your issue or feedback [For issues about inaccessible websites or slow speeds, please use the dedicated category for feedback, otherwise your request will not be processed].',
						isFinal: true,
						isRequired: true
					}
				]
			},
			{
				label: 'Mac苹果（电脑端）',
				labelEn: 'MacOS',
				key: 'MAC_DEVICE',
				solution: 'Mac苹果电脑目前正在等待苹果审核，敬请期待。或者您可以使用三方客户端作为备选方案，请访问仪表板查看使用说明：https://www.alphacloud.cc/',
				solutionEn: 'Mac is currently pending Apple review. Please stay tuned. Alternatively, you can use third-party clients as a backup solution. Please visit the dashboard for instructions: https://www.alphacloud.cc/',
				additional: '如有其他问题，请在下方反馈。',
				additionalEn: 'If you have any other questions, please provide feedback below.',
				isFinal: true,
				isRequired: true
			}
		]
	},
	{
		label: '账户问题',
		labelEn: 'Account Problems',
		key: 'ACCOUNT_PROBLEM',
		next: [
			{
				label: '找回密码',
				labelEn: 'Reset Password',
				key: 'FIND_PASSWORD',
				solution: '请在本App主界面【菜单】-【账户信息】中重置您的密码。',
				solutionEn: 'Please reset your password in [Menu] - [Account Information] on the app\'s main interface.',
				additional: '如上面的法方未解决您的问题，请在下方反馈。',
				additionalEn: 'If the above method doesn\'t solve your problem, please provide feedback below.',
				isFinal: true,
				isRequired: true
			},
			{
				label: '多设备使用限制',
				labelEn: 'Device Usage Limits',
				key: 'MULTIPLE_DEVICE_LIMIT',
				solution: 'VIP会员可同时登录和使用4台设备。您可在设备管理中登出其他设备。',
				solutionEn: 'VIP members can log in and use up to 4 devices simultaneously. You can log out other devices in Device Management.',
				additional: '如上面的法方未能解答您的疑问，请在下方填写您的问题。',
				additionalEn: 'If the above method doesn\'t answer your question, please describe your issue below.',
				isFinal: true,
				isRequired: true
			}
		]
	},
	{
		label: '支付问题',
		labelEn: 'Payment Issues',
		key: 'PAYMENT_PROBLEM',
		next: [
			{
				label: '支付成功，VIP会员没有充上',
				labelEn: 'Payment successful but VIP membership not activated.',
				key: 'PAYMENT_SUCCESS_VIP_NOT_ACTIVATED',
				additional: '请在支付宝/微信中找到相应的支付账单，复制订单号，填入下方并提交',
				additionalEn: 'Please find the corresponding payment record in Alipay/Wechat pay, copy the order number, and submit it below.',
				isFinal: true,
				isRequired: true
			},
			{
				label: '其他',
				labelEn: 'Other',
				key: 'OTHER_PAYMENT_PROBLEM',
				additional: '请尽可能详细描述您遇到的支付问题。',
				additionalEn: 'Please describe your payment issue in as much detail as possible.',
				isFinal: true, isRequired: true
			},
		]
	},
	{
		label: '担心App被封？',
		labelEn: 'Worried about the app being blocked?',
		key: 'WORRY_APP_BLOCKED_PROBLEM',
		additional: '1、我们的公司注册地在加拿大，符合当地政府的法律法规。\n2、加速器App有内置防封升级机制，如果App被技术封锁，会发升级通知更新到新版本。\n3、如果官网被技术封锁，新的官网地址会更新至“菜单”-“访问官网按钮”，通过点击该按钮可访问最新官网地址。\n4、VIP用户有单独的线路，有更高的防封机制。\n\n如果上方说明未能解答疑问，请在下方填写您的疑问：',
		additionalEn: 'Our company is registered in Canada and complies with local laws and regulations.\n1. The accelerator app has a built-in anti-blocking upgrade mechanism. \n2. If the app encounters technical blocks, you\'ll receive upgrade notifications for the new version.\n3. If the official website is blocked, the new website address will be updated in "Menu" - "Visit Official Website" button. Click this button to access the latest website address.\n4. VIP users have exclusive routes with enhanced anti-blocking protection.',
		isRequired: true
	},
	{
		label: '其他问题',
		labelEn: 'Other Issues',
		key: 'OTHER_PROBLEM',
		additional: '请填写您遇到的问题【如果是加速器使用/找回密码/支付等问题，请使用专属类目进行反馈，否则将不予处理。】',
		additionalEn: 'Please describe your issue [For accelerator usage/password recovery/payment issues, please use the dedicated categories for feedback, otherwise your request will not be processed.]',
		isRequired: true
	}
]
const timezone = 'Asia/Shanghai';
export {
	questionTree,
	timezone
}