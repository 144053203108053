import { createI18n } from 'vue-i18n'
import en from './en'

function getDefaultLang() {
	const urlParams = new URLSearchParams(window.location.search)
	const langParam = urlParams.get('lang')
	return langParam === 'en' ? 'en' : 'zh'
}

const i18n = createI18n({
	legacy: true,  // 改为 true
	globalInjection: true,
	locale: getDefaultLang(),
	fallbackLocale: 'zh',
	messages: {
		zh: {},
		en
	},
	missing: (locale, key) => {
		// 当语言为中文时，直接返回 key 作为文本
		if (locale === 'zh') {
			return key
		}
		return ''
	}
})

export default i18n