<template>
	<div class="confirm-modal-container">
		<div class="confirm-box-container">
			<div class="confirm-box-text-container" v-if="!!this.confirmText">
				{{ this.confirmText }}
			</div>
			<slot></slot>
			<div class="confirm-box-btn-container">
				<div class="cancel-btn" @click="this.onCancel()">{{$t('取消')}}</div>
				<div class="confirm-btn" @click="this.onConfirm()">{{$t('确认')}}</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ConfirmModal",
	components: {},
	props: ['confirmText'],
	data() {
	},
	methods: {
		onConfirm() {
			this.$emit('confirm');
		},
		onCancel() {
			this.$emit('cancel');
		}
	}
}
</script>
<style scoped>
.confirm-modal-container {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.4em;
	box-sizing: border-box;
	backdrop-filter: blur(4px);
	top: 0;
	left: 0;
}

.confirm-box-container {
	width: 80%;
	display: flex;
	flex-direction: column;
	background-color: #000000;
	padding: 1.6em;
	border: 1px solid rgba(255, 137, 0, 0.4);
	border-radius: 0.2em;
}

.confirm-box-text-container {
	width: 100%;
	text-align: start;
	color: var(--primary-color);
	font-size: 1.6em;
	box-sizing: border-box;
	margin-bottom: 1em;
}

.confirm-box-btn-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
}

.cancel-btn{
	color: var(--primary-color);
	border: 1px solid var(--primary-color);
	border-radius: 0.2em;
	padding: 0.2em 0.4em;
	line-height: 1;
	font-size: 1.6em;
	cursor: pointer;
}

.confirm-btn {
	background-color: var(--primary-color);
	color: var(--background-color);
	border-radius: 0.2em;
	padding: 0.2em 0.4em;
	line-height: 1;
	font-size: 1.6em;
	cursor: pointer;
}

.cancel-btn:active, .confirm-btn:active {
	transform: scale(0.9);
}
</style>