<template>
	<div class="page-container" v-if="this.canShowContent === true">
		<div class="feedback-btn" @click="this.onNewFeedback()">{{$t("新建问题反馈")}}</div>

		<div class="feedback-des-container">
			<div class="feedback-des-title">{{$t("我的反馈")}}</div>
			<div class="feedback-des-content">{{$t("反馈问题处理时间：10:00~23:00（中国区时间），我们将尽快回复您，请耐心等待，稍安勿躁。")}}
			</div>
		</div>
		<div class="feedback-empty-container" v-if="this.topicList.length === 0">
			<img class="feedback-empty-icon" src="../assets/empty.svg" alt="empty">
			<div class="feedback-empty-text">{{$t("空")}}</div>
		</div>
		<div class="feedback-topic-list-container" v-else>
			<div v-for="item in this.topicList" :key="item.item" class="feedback-topic-list-item-wrapper"
			     @click="this.onTopicClicked(item.feedbackTopicId)">
				<div class="feedback-topic-list-item">
					<div class="feedback-topic-list-item-label">{{ this.getTopicLabel(item.userDescription) }}</div>
					<div class="feedback-topic-list-item-state">{{$t("状态：")}}<span
						:style="this.getStateStyle(item)">{{ $t(item.topicState) }}</span></div>
					<div class="feedback-topic-list-item-created-time">{{$t("创建时间：")}}{{ item.createdTimeString }}</div>
					<div class="feedback-topic-list-item-update-time">{{$t("更新时间：")}}{{ item.updatedTimeString }}</div>
				</div>
				<div>
					<img src="../assets/right-arrow.svg" alt="right arrow">
				</div>
			</div>
		</div>
	</div>
	<div class="page-container" v-else>
		{{$t("参数错误")}}
	</div>
</template>

<script>
import {verifyTicketSessionId, showLoading, hideLoading, postRequestAsync} from "@/utils/methods";

export default {
	name: "FeedbackPage",
	components: {},
	data() {
		return {
			topicList: [],
			ticketSessionId: null,
			canShowContent: true
		}
	},
	async mounted() {
		await this.initPage()
		await this.getAllTopics()
	},
	methods: {
		async initPage() {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				window.Android.onEvent('showLoading', JSON.stringify({tag: 'initPage'}))
			}
			// else if (window.chrome && window.chrome.webview) {
			// 	window.chrome.webview.postMessage(JSON.stringify({
			// 		type: 'showLoading',
			// 		tag: 'initPage'
			// 	}));
			// }
			else {
				showLoading()
			}
			try {
				this.ticketSessionId = this.$route.query.id;
				if (!this.ticketSessionId) {
					this.canShowContent = false
				} else {
					const isSessionValid = await verifyTicketSessionId(this.ticketSessionId)
					if (isSessionValid === false) {
						this.canShowContent = false
					}
				}
			} catch (e) {
				console.log(e.message)
			} finally {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'initPage'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'initPage'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		onTopicClicked(topicId) {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				// 触发自定义事件
				window.Android.onEvent('onTopicClicked', JSON.stringify({topicId: topicId}));
			} else if (window.chrome && window.chrome.webview) {
				window.chrome.webview.postMessage(JSON.stringify({
					type: 'onTopicClicked',
					topicId: topicId
				}));
			}
		},
		onNewFeedback() {
			if (window.Android && typeof window.Android.onEvent === 'function') {
				// 触发自定义事件
				window.Android.onEvent('createFeedback', '');
			} else if (window.chrome && window.chrome.webview) {
				window.chrome.webview.postMessage(JSON.stringify({
					type: 'createFeedback'
				}));
			} else {
				this.$router.push({path: '/createFeedback'})
			}

		},
		async getAllTopics() {
			try {
				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('showLoading', JSON.stringify({tag: 'getAllTopics'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'showLoading',
				// 		tag: 'getAllTopics'
				// 	}));
				// }
				else {
					showLoading()
				}
				const response = await postRequestAsync('customerServicePanel/getFeedbackTopicList', {
					ticketSessionId: this.ticketSessionId
				})
				if (response && response?.code === 200) {
					response.msg.sort((a, b) => b.updatedTimestamp - a.updatedTimestamp)
					this.topicList = response.msg
				}

			} finally {

				if (window.Android && typeof window.Android.onEvent === 'function') {
					window.Android.onEvent('hideLoading', JSON.stringify({tag: 'getAllTopics'}))
				}
				// else if (window.chrome && window.chrome.webview) {
				// 	window.chrome.webview.postMessage(JSON.stringify({
				// 		type: 'hideLoading',
				// 		tag: 'getAllTopics'
				// 	}));
				// }
				else {
					hideLoading()
				}
			}

		},
		getTopicLabel(text) {
			if (!text) {
				return ''
			}
			if (text.length <= 10) {
				return text
			}
			return text.slice(0, 10) + '...'
		},
		getStateStyle(item) {
			if (item.topicState === '等待回复') {
				return {
					'color': 'red'
				}
			}
			if (item.topicState === '已回复') {
				return {
					'color': 'green'
				}
			}
			if (item.topicState === 'closed') {
				return {
					'color': '#E6D5B8'
				}
			}
		}
	}
}
</script>

<style scoped>

.feedback-btn {
	background-color: var(--primary-color);
	width: fit-content;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 12px;
	border-radius: 4px;
	cursor: pointer;
	font-size: 22px;
	line-height: 1;
}

.feedback-des-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: start;
	margin-top: 16px;
	border-bottom: 1px solid rgba(230, 213, 184, 1);
}

.feedback-des-title {
	font-size: 24px;
	color: var(--white-color);
	padding-bottom: 4px;
}

.feedback-des-content {
	font-size: 16px;
	color: rgba(230, 213, 184, 0.8);
	text-align: start;
	padding-bottom: 8px;
}

.feedback-empty-icon {
	opacity: 0.8;
	width: 70%;
}

.feedback-empty-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 16px 0;
}

.feedback-empty-text {
	font-size: 22px;
	color: rgba(230, 213, 184, 0.8);
}

.feedback-topic-list-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: scroll;
	margin-top: 1em;

}

.feedback-topic-list-item {
	width: 100%;
	display: flex;
	flex-direction: column;
	color: var(--white-color);
	align-items: start;
}


.feedback-topic-list-item-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	border: 1px solid rgba(230, 213, 184, 0.4);
	border-radius: 0.3em;
	box-sizing: border-box;
	padding: 1em;
	margin: 0.4em 0;
	align-items: center;
	cursor: pointer;
}

.feedback-topic-list-item-wrapper:active {
	background-color: rgba(230, 213, 184, 0.4);
}

.feedback-topic-list-item-label {
	width: 100%;
	font-size: 1.6em;
	text-align: start;
	line-height: 1;
	margin-bottom: 0.4em;
}

.feedback-topic-list-item-state {
	width: 100%;
	font-size: 1.2em;
	text-align: start;
	line-height: 1;
	margin-bottom: 0.4em;
}

.feedback-topic-list-item-created-time {
	width: 100%;
	font-size: 1.2em;
	text-align: start;
	line-height: 1;
	color: rgba(230, 213, 184, 0.4);
	margin-bottom: 0.4em;
}

.feedback-topic-list-item-update-time {
	width: 100%;
	font-size: 1.2em;
	text-align: start;
	line-height: 1;
	color: rgba(230, 213, 184, 0.4);
}

</style>